<template>
    <div class="statistiques">
        <h1>{{ $t('stats.title')}}</h1>

        <StatsMain :stats="stats"></StatsMain>
        <div class="pathology-container">
            <h2>{{ $t('stats.pathologyStatTitle')}}</h2>
            <StatPathology :max="pathologyCount" :pathology="item" v-for="(item, idx) in pathologies" :key="idx"></StatPathology>
        </div>
    </div>
</template>

<script>
import StatsMain from './../../components/stats/StatsMain'
import StatPathology from './../../components/stats/StatPathology'
import axios from 'axios'
import store from './../../store'

export default {
    name: 'Statistiques',
    components : {
        StatsMain,
        StatPathology
    },
    data() {
        return {
            centerSelected : -1,
            doctorSelected : -1,
            pathologyCount : 0,
            stats: [],
            patologies : []
        }
    },
    mounted() {
        let config = {
            validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"stats/doctor/" + store.state.USER.infos.id, config)
            .then(function (response) {
                vm.pathologies = response.data.pathology
                vm.pathologyCount = response.data.pathology_max
                    vm.stats = response.data.main
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .statistiques {
        background: #fff;
        border-radius:10px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        margin:0 auto 80px auto;
        padding: 20px 40px;
        width:$boxWidth;
        max-width: calc(90% - 80px);
        position:relative;
        min-height: calc(100vh - 351px);
    }

    h1 {
        font-size:25px;
        font-family: $fontBlack;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top:20px;
    }

    .select-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:20px;
    }

    .filter-item {
        width:250px;
        max-width:90%;
        margin:10px;
    }

</style>